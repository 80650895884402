
const getCompanyLogo = (
  domain: string,
  size: number = 128, // Default size set to 128
  greyscale: boolean = false // Default greyscale set to false
) => {
  // Construct query string with size and optional greyscale
  const queryParams = new URLSearchParams({
    size: size.toString(),
    greyscale: greyscale ? "true" : ""
  });

  // Remove the 'greyscale' parameter if it's false
  if (!greyscale) {
    queryParams.delete('greyscale');
  }

  // Construct the full URL
  return `https://logo.clearbit.com/${domain}?${queryParams}`;
}

const getCompanyLogoUrlChecked = (url: string): string => {
  // Remove the 'https://' or 'http://' prefix and any subdomain prefix
  const withoutProtocol = url.replace(/^(https?:\/\/)?(www\.)?/, '');

  // Extract the domain name (assumes there's a . in the domain)
  const domainMatch = withoutProtocol.match(/([^\/]+)(\/|$)/);
  const domainName = domainMatch ? domainMatch[1] : '';

  // Construct the Clearbit URL using the getCompanyLogo function
  const clearbitUrl = getCompanyLogo(domainName); // Using default size and greyscale
  
  return clearbitUrl;
} 

const isUrlValid = (url: string): boolean => {
  // Regular expression to check if the URL is valid including those starting with 'www.' without a protocol
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol (optional)
    '(www\\.)?' + // 'www.' prefix (optional)
    '([a-z\\d]([a-z\\d-]*[a-z\\d])*)' + // domain name
    '(\\.[a-z]{2,})' + // top-level domain
    '(\\.[a-z]{2,})*' + // second-level domain (enforced at least two characters)
    '(\\:[0-9]+)?' + // port (optional)
    '(\\/[-a-z\\d%_.~+]*)*' + // path (optional)
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string (optional)
    '(\\#[-a-z\\d_]*)?$', // fragment locator (optional)
    'i' // case insensitive
  );

  // Additionally check that after 'www.' there is at least one dot followed by two or more characters
  if (/^www\.[a-z\d]+$/i.test(url)) {
    return false;
  }

  // URL is valid if it passes the regular expression test
  return pattern.test(url);
}

// Example usage:
/*
console.log(isUrlValid('www.kp')); // Outputs: false
console.log(isUrlValid('www.kps')); // Outputs: false
console.log(isUrlValid('www.Inoltra.co')); // Outputs: true
console.log(isUrlValid('http://kps.com')); // Outputs: true
console.log(isUrlValid('https://kps.com')); // Outputs: true
console.log(isUrlValid('www.co')); // Outputs: false
*/
export { isUrlValid, getCompanyLogo, getCompanyLogoUrlChecked }
 
  